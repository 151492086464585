









































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaLoaderCircle,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";

@Component({
  components: {
    DefaBaseButton,
    DefaLoaderCircle,
    DefaTransitionFadeOutIn,
  },
})
export default class ReportHeader extends Vue {
  @Prop({ required: true }) readonly dateRangeFormatted!: [Date, Date];
  @Prop({ default: 0 }) readonly totalPrice!: number;
  @Prop({ default: false }) readonly loading!: boolean;
  @Prop({ default: false }) readonly empty!: boolean;

  async downloadReport() {
    this.$emit("download:report");
  }
}
