import { httpClient } from "@/http";
import type { CancelToken } from "axios";
import type { Order } from "@defa-as/utils";

export interface Report {
  total: number;
  title: string;
  orders: Order[];
}

export const getReport = async (
  endPoint: string,
  dateRange: [Date, Date],
  cancelToken: CancelToken
) =>
  httpClient.get<
    [Report] | Record<string, Report>,
    [Report] | Record<string, Report>
  >(endPoint, {
    params: {
      fromDate: dateRange[0],
      toDate: dateRange[1],
    },
    cancelToken: cancelToken,
  });
