




























































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import type { CancelTokenSource } from "axios";
import axios from "axios";
import { startOfToday, subWeeks } from "date-fns";
import type { Nullable } from "@defa-as/utils";
import {
  formatDateShortWithDots,
  formatDatetime,
  parseDateShortWithDots,
} from "@defa-as/utils";
import ReportRow from "@/components/report/ReportRow.vue";
import ReportRowHeader from "@/components/report/ReportRowHeader.vue";
import ReportHeader from "@/components/report/ReportHeader.vue";
import ReportFilters, {
  GroupByOption,
} from "@/components/report/ReportFilters.vue";
import ReportRowHeaderGroupBy from "@/components/report/ReportRowHeaderGroupBy.vue";
import { ROUTE_NAMES } from "@/router/route-names";
import { NavigationGuardNext, Route } from "vue-router";
import { getReport, Report } from "@/http/requests/requests-report";

const oneWeekAgo = subWeeks(startOfToday(), 1);

@Component({
  components: {
    ReportRowHeaderGroupBy,
    ReportRow,
    ReportHeader,
    ReportRowHeader,
    ReportFilters,
  },
})
export default class ViewReport extends Vue {
  @Prop({ default: "" }) readonly groupBy!: string;
  @Prop({ default: "" }) readonly dateFrom!: string;
  @Prop({ default: "" }) readonly dateTo!: string;
  @Ref() reportTable!: HTMLTableElement;
  request: Nullable<CancelTokenSource> = null;
  loading = false;
  report: [Report?] | Record<string, Report> = [];

  get selectedGroupByOption() {
    return (
      this.groupByOptions.find(
        (groupByOption) => groupByOption.value === this.groupBy
      ) || this.groupByOptions[0]
    );
  }

  get groupByOptions() {
    return [
      {
        label: this.$t("report.grouping.none"),
        value: "none",
        endPoint: "/reports/orders",
      },
      {
        label: this.$t("report.grouping.retailers"),
        value: "retailers",
        endPoint: "/reports/retailers/orders",
      },
      {
        label: this.$t("report.grouping.models"),
        value: "models",
        endPoint: "/reports/cars/models/orders",
      },
    ];
  }

  get dateRange(): [Date, Date] {
    return [
      this.dateFrom ? parseDateShortWithDots(this.dateFrom) : oneWeekAgo,
      this.dateTo ? parseDateShortWithDots(this.dateTo) : startOfToday(),
    ];
  }

  get isGroupedReport() {
    return !Array.isArray(this.report);
  }

  get emptyReport() {
    return this.isGroupedReport
      ? Object.keys(this.report).length === 0
      : this.report.length === 0;
  }

  get dateRangeFormatted(): [string, string] {
    return [
      formatDateShortWithDots(this.dateRange[0]),
      formatDateShortWithDots(this.dateRange[1]),
    ] as [string, string];
  }

  get totalPrice() {
    if (this.isGroupedReport) {
      return Object.values(this.report as Record<string, Report>)
        .map((groupedReport) => groupedReport.total)
        .reduce((totalPrice, currentPrice) => totalPrice + currentPrice, 0);
    }
    return this.report[0]?.total;
  }

  beforeRouteEnter(
    from: Route,
    _: Route,
    next: NavigationGuardNext<ViewReport>
  ) {
    next(async (vm) => {
      const query = {
        groupBy: vm.selectedGroupByOption.value,
        dateFrom: vm.dateRangeFormatted[0],
        dateTo: vm.dateRangeFormatted[1],
      };
      const { route } = vm.$router.resolve({
        name: ROUTE_NAMES.REPORT,
        query,
      });
      if (route.fullPath !== vm.$route.fullPath) {
        await vm.$router.replace({
          name: ROUTE_NAMES.REPORT,
          query,
        });
      } else {
        // Can't rely $route.query watcher to load report in this case
        await vm.loadReport();
      }
    });
  }

  async onDownloadReport() {
    const xlsx = await import(/* webpackChunkName: "xlsx"  */ "xlsx");
    const workbook = xlsx.utils.table_to_book(this.reportTable);
    xlsx.writeFile(
      workbook,
      `${this.$t("report.download.filename")}-${formatDatetime(
        new Date()
      )}.xlsx`
    );
  }

  async loadReport() {
    if (this.request) {
      this.request.cancel();
    }
    this.request = axios.CancelToken.source();
    this.loading = true;
    try {
      const report = await getReport(
        this.selectedGroupByOption.endPoint,
        this.dateRange,
        this.request.token
      );
      this.report = report;
      this.loading = false;
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.loading = false;
      }
    }
  }

  async onInputGroupBy(groupByOption: GroupByOption["value"]) {
    if (this.$route.query.groupBy !== groupByOption) {
      await this.$router.push({
        name: ROUTE_NAMES.REPORT,
        query: {
          groupBy: groupByOption,
          dateFrom: this.dateRangeFormatted[0],
          dateTo: this.dateRangeFormatted[1],
        },
      });
    }
  }

  async onInputDateRange(value: [Date, Date]) {
    const dateFrom = formatDateShortWithDots(value[0]) as string;
    const dateTo = formatDateShortWithDots(value[1]) as string;
    if (
      this.$route.query.dateFrom !== dateFrom ||
      this.$route.query.dateTo !== dateTo
    ) {
      await this.$router.push({
        name: ROUTE_NAMES.REPORT,
        query: {
          groupBy: this.selectedGroupByOption.value,
          dateFrom,
          dateTo,
        },
      });
    }
  }

  @Watch("$route.query")
  async onRouteChange() {
    await this.loadReport();
  }
}
