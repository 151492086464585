

















































































import { Component, Prop, Vue } from "vue-property-decorator";
import type { Order } from "@defa-as/utils";
import { formatDate, OrderState } from "@defa-as/utils";
import BaseStatus from "@/components/BaseStatus.vue";
import { ROUTE_NAMES } from "@/router/route-names";

@Component({
  components: {
    BaseStatus,
  },
})
export default class ReportRow extends Vue {
  @Prop({ required: true }) readonly order!: Order;

  get orderDetailsLink() {
    return {
      name: ROUTE_NAMES.ORDERS.DETAILS.PROGRESS,
      params: {
        id: this.order.id,
      },
    };
  }

  getState(attribute: keyof OrderState, emptyText = "-") {
    if (this.order.state[attribute].state) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const date = this.order.state[attribute].dateUpdated.date;
      return formatDate(date);
    }
    return emptyText;
  }
}
