















import { Component, Prop, Vue } from "vue-property-decorator";
import { formatCurrency } from "@/filters";

@Component
export default class ReportRowHeaderGroupBy extends Vue {
  @Prop({ required: true }) readonly groupInfo!: {
    title: string;
    total: number;
  };

  get totalPriceAsCurrency() {
    return formatCurrency(this.groupInfo.total);
  }
}
