





















import { Component, Prop, Vue } from "vue-property-decorator";
import type { TranslateResult } from "vue-i18n";
import { isBefore, isFuture } from "date-fns";
import { DefaBaseDatetimeInput } from "@defa-as/components";
import BaseSelect from "@/components/forms/inputs-generic/BaseSelect.vue";

export type GroupByOption = {
  label: string | TranslateResult;
  value: string;
  endPoint: string;
};

@Component({
  components: {
    DefaBaseDatetimeInput,
    BaseSelect,
  },
})
export default class ReportFilters extends Vue {
  @Prop({ required: true }) readonly groupByValue!: GroupByOption;
  @Prop({ required: true }) readonly groupByOptions!: GroupByOption[];
  @Prop({ required: true }) readonly dateRangeValue!: [Date, Date];
  @Prop({ required: true }) readonly dateRangeFormatted!: [Date, Date];

  get disableFutureDatesAndDatesBeforeFirstOrder() {
    const creationDateOfFirstOrder = new Date(2018, 7, 8); // 8th of August 2018, #FK-100001
    return (date: Date) =>
      isFuture(date) || isBefore(date, creationDateOfFirstOrder);
  }

  get datetimeInputPlaceholder() {
    if (this.dateRangeValue) {
      return this.$t("report.rangeFilterLegendFilled", {
        dateFrom: this.dateRangeFormatted[0],
        dateTo: this.dateRangeFormatted[1],
      });
    }
    return this.$t("report.rangeFilterLegend");
  }

  get groupByForSelect() {
    return this.groupByValue.value;
  }

  set groupByForSelect(value: GroupByOption["value"]) {
    this.$emit("input:group-by", value);
  }

  get dateRange() {
    return this.dateRangeValue;
  }

  set dateRange(value: [Date, Date]) {
    this.$emit("input:date-range", value);
  }
}
